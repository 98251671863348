import { tns } from 'tiny-slider/src/tiny-slider'

document.addEventListener('DOMContentLoaded', function () {
  let slider = tns({
    container: '.hero-gallery__tns',
    items: 1,
    navPosition: 'bottom',
    // mode: 'gallery',
    autoHeight: true,
    mouseDrag: true,
    slideBy: 'page'
  })
})
